import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const StorybookEmbed = makeShortcode("StorybookEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Note mdxType="Note">
  Please note Primer CSS v18 has changed the naming of these color classes. Check the{' '}
  <a href="/css/support/v18-migration">migration guide</a> to make sure your app is up to date.
    </Note>
    <p>{`These utilities should only be used where color is not provided by a component. To learn more about how to apply color, read the `}<a parentName="p" {...{
        "href": "/foundations/color"
      }}>{`color documentation`}</a>{`.`}</p>
    <h2>{`Text`}</h2>
    <p>{`Use text color utilities to set text to a specific color. Color contrast must pass a minimum WCAG accessibility rating of `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-contrast.html"
      }}>{`level AA`}</a>{`. This ensures that viewers who cannot see the full color spectrum are able to read the text. To customize outside of the suggested combinations below, we recommend using this `}<a parentName="p" {...{
        "href": "https://colorable.jxnblk.com/"
      }}>{`color contrast testing tool`}</a>{`. For more information, read our `}<a parentName="p" {...{
        "href": "/guides/accessibility"
      }}>{`accessibility standards`}</a>{`.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-color--new-text'
    }, {
      id: 'utilities-color--text'
    }]} height="410" mdxType="StorybookEmbed" />
    <p>{`You can set the color inheritance on an element by using the `}<inlineCode parentName="p">{`color-fg-inherit`}</inlineCode>{` class.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-color--text-inherit'
    }]} height="60" mdxType="StorybookEmbed" />
    <h2>{`Background`}</h2>
    <p>{`Background colors are most commonly used for filling large blocks of content or areas with a color. When selecting a background color, make sure the foreground color contrast passes a minimum WCAG accessibility rating of `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-contrast.html"
      }}>{`level AA`}</a>{`. Meeting these standards ensures that content is accessible by everyone, regardless of disability or user device. You can `}<a parentName="p" {...{
        "href": "https://colorable.jxnblk.com/"
      }}>{`check your color combination with this demo site`}</a>{`. For more information, read our `}<a parentName="p" {...{
        "href": "/guides/accessibility"
      }}>{`accessibility standards`}</a>{`.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-color--new-background'
    }, {
      id: 'utilities-color--background'
    }]} height="1250" mdxType="StorybookEmbed" />
    <h2>{`Border`}</h2>
    <p>{`Override default border colors with the following utilities.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-color--new-border'
    }, {
      id: 'utilities-color--border'
    }]} height="1170" mdxType="StorybookEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      